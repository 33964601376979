<template>
  <div class="container p-error-page">
    <div class="row">
      <div class="col-lg-6">
        <img class="d-block d-md-block d-lg-none d-xl-none logo-small" :src="getAppLogo" />

        <h2 class="p-error-page__code mb-0">{{ error.statusCode === 404 ? '404' : (error.message ? (JSON.parse(error.message).status ? `${JSON.parse(error.message).status} - ${JSON.parse(error.message).statusText}` : 'An error occured') : 'An error occured') }}</h2>
        <h3 class="p-error-page__title m-0">{{ error.statusCode === 404 ? "Looks like you're lost" : (error.message ? (JSON.parse(error.message).data ? `${JSON.parse(error.message).data}` : (JSON.parse(error.message).statusText ? JSON.parse(error.message).statusText : error.message)) : error) }}</h3>
        <div class="p-error-page__divider title-devider"></div>
        <p class="mb-4">
          You seem to be trying to find your way home
        </p>
        <NuxtLink class="btn waves-effect mb-2" to="/">Back to Home</NuxtLink>
        <footer>
          <small>Copyright &copy; {{ getYear }} {{ getCompanyName }}</small>
        </footer>
      </div>
      <div class="col-lg-6">
        <center>
          <img class="d-none d-md-none d-lg-block d-xl-block logo-big" :src="getAppLogo" />
        </center>
        <img alt="Responsive image" class="img-fluid d-none d-lg-block d-xl-block" src="/images/svg/lost.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { humanizeTimestamp } from '~/helpers/date';

export default {
  name: 'PageError',
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: () => {
        return { statusCode: 404 };
      },
    },
  },
  computed: {
    getYear() {
      return humanizeTimestamp(new Date().getTime(), '%yyyy%');
    },
    getAppLogo() {
      return process.env.APP_LOGO_COLOR;
    },
    getCompanyName() {
      return process.env.COMPANY_NAME;
    },
  },
};
</script>

<style lang="scss" scoped>
.p-error-page {
  color: #99abb4;
  font-family: Poppins, sans-serif;
  margin-top: 8%;
  max-width: 1140px !important;

  .logo-small {
    height: 30px;
    margin-top: 20px;
  }

  .logo-big {
    height: 30px;
    margin-top: -30px;
    margin-bottom: 60px;
  }

  &__code {
    color: #455a64;
    font-size: 35px;
    margin-top: 50px;
    font-weight: 300;
  }

  &__title {
    color: #455a64;
    font-size: 45px;
    font-weight: 800;
  }

  &__divider {
    height: 5px;
    width: 75px;
    background: #1e88e5;
    margin-bottom: 20px;
  }

  .btn {
    color: #fff;
    background: #1e88e5;
    border: 1px solid #1e88e5;
    box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    transition: 0.2s ease-in;
    border-radius: 60px;
    padding: 7px 18px;
    font-size: 14px;

    &:hover,
    &:focus {
      box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    }

    &:active {
      color: #fff;
      background-color: #117a8b;
      border-color: #10707f;
    }
  }

  .waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    will-change: opacity, transform;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    -ms-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
  }
}
</style>
