import Vue from 'vue';
import Router from 'vue-router';
import r from '~/utils/render-utils';

/**
 * Code Prefetch
 * Ref: https://nuxtjs.org/api/components-nuxt-link/
 * To improve the responsiveness of your Nuxt.js applications, when the link will be displayed within the viewport,
 * Nuxt.js will automatically prefetch the code splitted page. This feature is inspired by quicklink.js by Google Chrome Labs.
 *
 * Summary: Based on the explanation above, if one or more page is in focus, the system will automatically load the required files on idle.
 */

//  Modules, split into modules for easier debugging

// General page, only for the pages that belong to no module
const PageRedirector = () => import(/* webpackChunkName: 'redirector' */ '~/pages/redirector');
const PageLogin = () => import(/* webpackChunkName: 'login' */ '~/pages/login');
const PageLogout = () => import(/* webpackChunkName: 'logout' */ '~/pages/logout');
const PageDashboard = () => import(/* webpackChunkName: 'dashboard' */ '~/pages/index');
const PrePages = () => import(/* webpackChunkName: 'pre-pages' */ '~/pages/pre-pages');

const PageKegiatanList = () => import(/* webpackChunkName: 'kegiatan-list' */ '~/pages/kegiatan/list');
const PageKegiatanCreate = () => import(/* webpackChunkName: 'kegiatan-create' */ '~/pages/kegiatan/create');
const PageKegiatanDetail = () => import(/* webpackChunkName: 'kegiatan-detail' */ '~/pages/kegiatan/detail');
const PageKegiatanEdit = () => import(/* webpackChunkName: 'kegiatan-edit' */ '~/pages/kegiatan/edit');
const PageKegiatanPlayground = () => import(/* webpackChunkName: 'kegiatan-playground' */ '~/pages/kegiatan/playground');

const PageClientList = () => import(/* webpackChunkName: 'client-list' */ '~/pages/client/list');

const PageUserList = () => import(/* webpackChunkName: 'user-list' */ '~/pages/user/list');
const PageLanggananList = () => import(/* webpackChunkName: 'langganan-list' */ '~/pages/langganan/list');

const PageChangePassword = () => import(/* webpackChunkName: 'ganti-password' */ '~/pages/ganti-password');

Vue.use(Router);

const routes = [
  // Pages
  {
    path: '/',
    component: r(PageDashboard),
    name: 'dashboard',
  },
  {
    path: '/ganti-password',
    component: r(PageChangePassword),
    name: 'ganti-password',
  },
  {
    path: '/login',
    component: r(PageLogin),
    name: 'login',
  },
  {
    path: '/logout',
    component: r(PageLogout),
    name: 'logout',
  },
  {
    path: '/kegiatan',
    component: r(PrePages),
    children: [
      {
        path: '/',
        component: r(PageKegiatanList),
        name: 'kegiatan-list',
      },
      {
        path: 'create',
        component: r(PageKegiatanCreate),
        name: 'kegiatan-create',
      },
      {
        path: ':id(\\d+)',
        component: r(PrePages),
        children: [
          {
            path: '',
            component: r(PageKegiatanDetail),
            name: 'kegiatan-detail',
          },
          {
            path: 'playground',
            component: r(PageKegiatanPlayground),
            name: 'kegiatan-playground',
          },
          {
            path: 'edit',
            component: r(PageKegiatanEdit),
            name: 'kegiatan-edit',
          },  
        ],
      },
    ],
  },
  {
    path: '/client',
    component: r(PrePages),
    children: [
      {
        path: '/',
        component: r(PageClientList),
        name: 'client-list',
      },
    ],
  },
  {
    path: '/user',
    component: r(PrePages),
    children: [
      {
        path: '/',
        component: r(PageUserList),
        name: 'user-list',
      },
    ],
  },
  {
    path: '/langganan',
    component: r(PrePages),
    children: [
      {
        path: '/',
        component: r(PageLanggananList),
        name: 'langganan-list',
      },
    ],
  },
  

  // Leave this at the very bottom of the page to handle 404 page, that will be redirected to /
  {
    path: '*',
    component: r(PageRedirector),
    name: 'redirector_page',
  },
];

const appRouter = new Router({
  mode: 'history',
  routes,
});

export function createRouter() {
  return appRouter;
}

export default appRouter;
