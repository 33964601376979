import ApiOptions from '../api-options';

export default {
  list(requestPayload) {
    return this.get('/api/v1/types', 'type', new ApiOptions({ requestPayload }));
  },
  detail(requestPayload) {
    return this.get('/api/v1/types', 'type', new ApiOptions({ requestPayload }));
  },
};
