// Store for Authorization
import ApiClient from '~/api';

const initialState = {
};

const getters = {
};

const actions = {
  async login({ dispatch }, params = null) {
    try {
      const { email, password } = params;
      const res = await ApiClient.login({ email, password });
      if (typeof res.data !== 'undefined') {
        this.$cookies.set('user', res.data);
        this.$cookies.set('cxp_token', res.data.token);
        dispatch(
          'user/setUser',
          {
            user: res.data,
          },
          { root: true },
        );
      } else {
        throw new Error(['Invalid Email and/or Password']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async logout() {
    try {
      await ApiClient.logout();
    } finally {
      this.$cookies.remove('user');
      this.$cookies.remove('cxp_token');
    }
  },
};

const mutations = {
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
