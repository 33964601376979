import ApiOptions from '../api-options';

export default {
  list(requestPayload) {
    return this.get('/api/v1/options', 'type', new ApiOptions({ requestPayload }));
  },
  detail(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.get(`/api/v1/options/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
};
