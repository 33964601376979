var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['toast', 'c-toast-item', `c-toast--${_vm.toast.type}`]},[_c('div',{staticClass:"toast-header"},[_c('i',{class:[
        'fa',
        'mr-2',
        {
          'fa-check': _vm.toast.type === 'success',
          'fa-info-circle': _vm.toast.type === 'info',
          'fa-exclamation-circle': _vm.toast.type === 'warning',
          'fa-exclamation-triangle': _vm.toast.type === 'error',
        },
      ]}),_vm._v(" "),_c('strong',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.toast.type)))]),_vm._v(" "),_c('button',{staticClass:"ml-2 mb-1 close",attrs:{"type":"button"},on:{"click":_vm.clickClose}},[_c('span',[_vm._v("×")])])]),_vm._v(" "),_c('div',{staticClass:"toast-body"},[_vm._v("\n    "+_vm._s(_vm.toast.message)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }