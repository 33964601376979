<template>
  <div>
    <Header />
    <nuxt />
    <Footer />
    <Toast />
  </div>
</template>

<script>
import Header from '~/components/template/Header';
import Footer from '~/components/template/Footer';
import Toast from '~/components/toast';

export default {
  name: 'LayoutDefault',
  middleware: 'authenticated',
  transition: 'fade',
  components: { Header, Footer, Toast },
  async beforeCreate() {
    await this.$store.dispatch('user/retrieveFromCookies');
  },
};
</script>

<style lang="scss">
html {
  min-height: 100%;
  height: 100%;
}

body {
  background-color: #fff;
}
</style>
