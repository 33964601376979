<template>
  <nuxt />
</template>

<script>
export default {
  name: 'LayoutEmpty',
  transition: 'fade',
};
</script>

<style lang="scss">
body {
  background-color: #f4f5f7;
}
</style>
