/**
 * @module helpers/number
 */
/**
 * Return random integer in between min and max
 *
 * @example
 * getRandomInt(0, 5)
 * // => 0, 1, 2, 3, 4 or 5
 *
 * @param  {Number} min minimum possible value
 * @param  {Number} max maximum possible value
 *
 * @return {Number}
 */
export function getRandomInt(min, max) {
  if (max < min) {
    throw new Error('Max must be greater or equal than min');
  }

  // eslint-disable-next-line no-mixed-operators
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Delimit number
 *
 * @example
 * delimiter(1000000)
 * // => '1.000.000'
 *
 * @param  {Number|String} val Number to delimit
 * @return {String} Delimited number
 */
export function delimiter(val) {
  const str = `${val}`; // convert to string
  const delimited = str.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${val < 0 ? '-' : ''}${delimited}`;
}

/**
 * Convert number to money
 *
 * @example
 * money(1000000)
 * // => 'Rp1.000.000'
 * money(1000000, 'usd')
 * // => '$1.000.000'
 *
 * @param  {Number|String} val Number to convert
 * @return {String} Converted number in money format
 */
export function money(val, currency = 'idr') {
  const delimited = delimiter(val);
  if (delimited.charAt(0) === '-') {
    return delimited.replace(/^-/g, currency.toLowerCase() === 'idr' ? '-IDR' : '-USD');
  }
  return currency.toLowerCase() === 'idr' ? `IDR ${delimited}` : `USD ${delimited}`;
}

export default {
  getRandomInt,
  delimiter,
  money,
};
