const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const daysID = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const monthsID = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Agt', 'Sep', 'Oct', 'Nov', 'Dec'];
const monthsShortID = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des'];

export function humanizeTimestamp(timestamp, format = '%day%, %dd% %month% %yyyy%', lang = 'EN') {
  const dateTime = new Date(timestamp  * 1000);
  const selectedDays = lang === 'ID' ? daysID : days;
  const selectedMonths = lang === 'ID' ? monthsID : months;
  const selectedMonthsShort = lang === 'ID' ? monthsShortID : monthsShort;
  const timeMapping = {
    '%hh%': `0${dateTime.getUTCHours()}`.slice(-2),
    '%mi%': `0${dateTime.getUTCMinutes()}`.slice(-2),
    '%dd%': `0${dateTime.getUTCDate()}`.slice(-2),
    '%day%': selectedDays[dateTime.getUTCDay()],
    '%mm%': `0${dateTime.getUTCMonth() + 1}`.slice(-2),
    '%mmm%': selectedMonthsShort[dateTime.getUTCMonth()],
    '%month%': selectedMonths[dateTime.getUTCMonth()],
    '%yyyy%': dateTime.getUTCFullYear(),
  };
  return format.replace(/%(.*?)%/gi, n => timeMapping[n]);
}

export const prependZero = number => (Number(number) < 10 ? `0${Number(number).toString()}` : Number(number).toString());

/**
 * Time Filter
 * @param value {string} ISO 8601 format, e.g '2017-01-31T02:30:00+07:00'
 * @return hour:minute e.g '02:30'
 */
export const customTime = value => {
  if (value === undefined) {
    throw new Error('value cant be null');
  }

  if (typeof value === 'number') {
    throw new Error('type must be string or date');
  }

  if (typeof timestamp === 'string') {
    return value.substring(11, 16);
  }
  if (value instanceof Date) {
    return `${prependZero(value.getHours())}:${prependZero(value.getMinutes())}`;
  }
  const date = new Date(value);
  return `${prependZero(date.getHours())}:${prependZero(date.getMinutes())}`;
};

export function timeDiff(start, end, unit = 'ms') {
  const diff = end - start;

  switch (unit) {
    case 's':
      return diff / 1000;
    default:
      return diff;
  }
}

export function timeDiffSeconds(start, end) {
  return timeDiff(start, end, 's');
}

/*
 * Returns the number of milliseconds between midnight of January 1, 1970 and the specified date
 */
export function getCurrentTime() {
  return new Date().getTime();
}

export function getMonth(month) {
  return months[month];
}

export function getMonths(min = 0, max = 12) {
  const ms = [
    {
      id: '01', 
      label: 'January',
    },
    {
      id: '02', 
      label: 'February',
    },
    {
      id: '03', 
      label: 'March',
    },
    {
      id: '04', 
      label: 'April',
    },
    {
      id: '05', 
      label: 'May',
    },
    {
      id: '06', 
      label: 'June',
    },
    {
      id: '07', 
      label: 'July',
    },
    {
      id: '08', 
      label: 'August',
    },
    {
      id: '09', 
      label: 'September',
    },
    {
      id: '10', 
      label: 'October',
    },
    {
      id: '11', 
      label: 'November',
    },
    {
      id: '12', 
      label: 'December',
    },
  ];

  return ms.slice(min,max);
}

export function getActiveYears(min = 0, max = 5) {
  const ret = [];
  const year = new Date().getFullYear();
  for (let index = min; index < max; index += 1) {
    ret.push({ id: year + index, label: year + index });
  }

  return ret;
}

export default { humanizeTimestamp, prependZero, customTime, timeDiff, timeDiffSeconds, getCurrentTime, getMonth, getMonths, getActiveYears };
