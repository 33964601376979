import { getRandomInt } from '~/helpers/number';

const initialState = {
  toasts: [],
};

const getters = {
  toasts: state => state.toasts,
};

const actions = {
  async showMessage({ commit }, { message, type, autoHide = true }) {
    await commit('addToast', { message, type, autoHide });
  },
  removeMessage({ commit }, { id }) {
    commit('removeToast', id);
  },
  removeAllMessage({ commit }) {
    commit('removeAllToast');
  },
};

const mutations = {
  addToast(state, { message, type, autoHide }) { // type= success,warning,error,info
    const id = `t_${new Date().getTime()}_${getRandomInt(10, 99)}`;
    state.toasts.push({ id, message, type, autoHide });
  },
  removeToast(state, id) {
    state.toasts = state.toasts.filter(i => {
      return i.id !== id;
    });
  },
  removeAllToast(state) {
    state.toasts = [];
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
