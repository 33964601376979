<template>
  <div :class="['toast', 'c-toast-item', `c-toast--${toast.type}`]">
    <div class="toast-header">
      <i
        :class="[
          'fa',
          'mr-2',
          {
            'fa-check': toast.type === 'success',
            'fa-info-circle': toast.type === 'info',
            'fa-exclamation-circle': toast.type === 'warning',
            'fa-exclamation-triangle': toast.type === 'error',
          },
        ]"
      ></i>
      <strong class="mr-auto">{{ toast.type | capitalize }}</strong>
      <button class="ml-2 mb-1 close" type="button" @click="clickClose">
        <span>&times;</span>
      </button>
    </div>
    <div class="toast-body">
      {{ toast.message }}
    </div>
  </div>
</template>

<script>
import { capitalize } from '~/helpers/string';

export default {
  name: 'ToastItem',
  filters: { capitalize },
  props: {
    toast: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    autoHide: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      theTimeout: null,
    };
  },
  mounted() {
    this.waitingTime = 6000 + this.index * 3000;
    if (this.toast.autoHide === true) {
      this.theTimeout = setTimeout(() => {
        this.clickClose();
      }, this.waitingTime);
    }
  },
  methods: {
    clickClose() {
      clearTimeout(this.theTimeout);
      this.$emit('close', this.toast.id);
    },
  },
};
</script>
