/* eslint-disable no-unused-vars */
import ApiClient from '~/api';

const initialState = {
  list: [],
  detail: {},
};

const getters = {
  getList: state => state.list,
  getDetail: state => state.detail,
};

const actions = {
  async retrieveList({ commit }, params = null) {
    try {
      const res = await ApiClient.getListOption(params);
      if (typeof res.data !== 'undefined') {
        commit('setList', res.data.list);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  clearList({ commit }) {
    commit('setList', []);
  },
  async retrieveDetail({ commit }, params = null) {
    try {
      const res = await ApiClient.getDetailOption(params);
      if (typeof res.data !== 'undefined') {
        console.info(res.data);
        commit('setDetail', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
};

const mutations = {
  setList(state, value) {
    state.list = value;
  },
  setDetail(state, value) {
    state.detail = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
