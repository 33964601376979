export const generateGuid = () => {
  const rand4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return `${rand4()}${rand4()}-${rand4()}-${rand4()}-${rand4()}-${rand4()}${rand4()}${rand4()}`;
};

export default { generateGuid };
