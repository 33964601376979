import ApiOptions from '../api-options';

export default {
  gantiPassword(requestPayload) {
    return this.post('/api/ganti-password', 'type', new ApiOptions({ requestPayload }));
  },
  kuotaKegiatan(requestPayload) {
    return this.get('/api/kuota-kegiatan', 'type', new ApiOptions({ requestPayload }));
  },
  home(requestPayload) {
    return this.get('/api/home', 'type', new ApiOptions({ requestPayload }));
  },

  list(requestPayload) {
    return this.get('/api/users', 'type', new ApiOptions({ requestPayload }));
  },
  submit(requestPayload) {
    return this.post('/api/users', 'type', new ApiOptions({ requestPayload }));
  },
  edit(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.put(`/api/users/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  detail(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.get(`/api/users/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  delete(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.delete(`/api/users/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
};
