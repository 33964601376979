/**
 * @module helpers/string
 */
export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export function capitalize(value) {
  if (!value) return '';
  const valueAsString = value.toString();
  return valueAsString.charAt(0).toUpperCase() + valueAsString.slice(1);
}

export function capitalizeEachWord(value) {
  if (!value) return '';
  const words = value.split(' ');
  const values = [];
  words.forEach(word => {
    values.push(capitalize(word));
  });
  return values.join(' ');
}

export function camelCase(value) {
  return value.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function lowerCase(value) {
  return value.toLowerCase();
}

export function password(value) {
  return value
    .split('')
    .map(() => '*')
    .join('');
}

export default {
  capitalize,
  capitalizeEachWord,
  camelCase,
  lowerCase,
  password,
};
