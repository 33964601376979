import ApiOptions from '../api-options';

export default {
  list(requestPayload) {
    return this.get('/api/clients', 'type', new ApiOptions({ requestPayload }));
  },
  submit(requestPayload) {
    return this.post('/api/clients', 'type', new ApiOptions({ requestPayload }));
  },
  edit(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.put(`/api/clients/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  detail(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.get(`/api/clients/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  delete(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.delete(`/api/clients/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
};
