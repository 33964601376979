/* eslint-disable camelcase */
import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { alpha_dash, email, max , max_value , min, numeric, required } from 'vee-validate/dist/rules';
// import { messages } from 'vee-validate/dist/locale/en.json';

extend('required', { ...required, message: 'This field is required' });
extend('alpha_dash', { ...alpha_dash, message: 'This field can only contain alphabetic characters, numbers, dashes or underscores' });
extend('min', { ...min, message: 'This field must be greater than {length}' });
extend('max', { ...max, message: 'This field may not be greater than {length}' });
extend('email', { ...email, message: 'This field should be a valid email address' });
extend('numeric', { ...numeric, message: 'The field may only contain numeric characters' });
extend('max_value', { ...max_value, message: 'The field must be {max} or less'  });
extend('positive', { validate: value => value >= 0, message: 'This field should contain positive value' });
extend('odd', { validate: value => value % 2 !== 0, message: 'This field should be odd' });

/**
 * To validate a field based on the requirements givent
 *
 * @example
 * <ValidationProvider v-slot="{ errors }" rules="valid_password">...</ValidationProvider>
 *
 * @returns {Object} Response object that represents the valid state of the field that is being validated
 */
extend('valid_password', {
  validate: value => {
    if (value.length >= 8 && value.length <= 32) {
      const uppercase = value.match(/[A-Z]/g) || [];
      const lowercase = value.match(/[a-z]/g) || [];
      const numbers = value.match(/[0-9]/g) || [];
      if (uppercase.length >= 1 && lowercase.length >= 1 && numbers.length >= 1) return true;
    }
    return false;
  },
  message: 'This field must be at 8-32 characters long, contain at least 1 uppercase, 1 lowercase, and 1 number',
});

/**
 * To validate a field based on the other field, usually to check the validity of a password confirmation field.
 * Reference: https://logaretm.github.io/vee-validate/advanced/cross-field-validation.html#targeting-other-fields
 *
 * @example
 * <ValidationProvider v-slot="{ errors }" rules="valid_hostname">...</ValidationProvider>
 *
 * @returns {Object} Response object that represents the valid state of the field that is being validated
 */
extend('match_password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match',
});


extend('min_date', {
  params: ['target'],
  validate(value, { target }) {
    return value >= target;
  },
  message(value, { target }) {
    return `This field cannot be less than ${target}`;
  },
});

/**
 * To validate a field based on the requirements givent
 *
 * @example
 * <ValidationProvider v-slot="{ errors }" rules="valid_hostname">...</ValidationProvider>
 *
 * @returns {Object} Response object that represents the valid state of the field that is being validated
 */
extend('valid_hostname', {
  validate: value => {
    // Regex tested using https://regex101.com/r/rH040T/1
    return !!value.match(/^[A-Za-z]+[A-Za-z0-9-]*[A-Za-z0-9]$/g);
  },
  message: 'This field must begin with alphabet, and can only contain alphabetic characters, numbers, dashes.',
});

/**
 * To validate a field based on a dropdown using <SelectInput>.
 * To validate a field based on a standard HTML input, consider using required_if, https://logaretm.github.io/vee-validate/guide/rules.html#required-if
 * Reference: https://logaretm.github.io/vee-validate/guide/required-fields.html
 *
 * @example
 * <ValidationProvider v-slot="{ errors }" rules="custom_required_if:@schedule,id,manual">...</ValidationProvider>
 *
 * @returns {Object} Response object that represents the required and valid state of the field that is being validated
 */
extend('custom_required_if', {
  params: ['target', 'id', 'text'],
  validate(value, { target, id, text }) {
    if (target !== null) {
      if (target[id] === text) {
        return {
          required: true,
          valid: ['', null, undefined].indexOf(value) === -1,
        };
      }
    }
    return {
      required: true,
      valid: true,
    };
  },
  computesRequired: true,
  message: 'This field is required',
});

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
