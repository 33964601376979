/* eslint-disable no-lonely-if */
const getErrorMessage = (error = null, message = '') => {
  let errorMessage = null;

  if (error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      errorMessage = `[${error.response.status}] ${error.response.data}`;
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      errorMessage = error.request;
    } else if (error.message) {
      // Something happened in setting up the request that triggered an Error
      if (error.message === 'REQUEST LIMITED') {
        errorMessage = 'You requested this data too often. Please wait in a few minutes.';
      }
    }
  }

  // if (process.env.SERVER_NAME === 'local') {
  //   return JSON.stringify({
  //     user: message,
  //     system: errorMessage,
  //     debug: typeof error === 'object' ? error.message : error,
  //   });
  // }

  if (errorMessage === null) return message;
  return errorMessage;
};

const initialState = {};
const getters = {};

const actions = {
  catchError({ dispatch }, { error, message, type = 'error' }) {
    dispatch(
      'toast/showMessage',
      {
        message: getErrorMessage(error, message),
        type,
        autoHide: false,
      },
      { root: true },
    );
  },
};

const mutations = {};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
