import ApiOptions from '../api-options';

export default {
  list(requestPayload) {
    return this.get('/api/kegiatans', 'type', new ApiOptions({ requestPayload }));
  },
  submit(requestPayload) {
    return this.post('/api/kegiatans', 'type', new ApiOptions({ requestPayload }));
  },
  edit(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.put(`/api/kegiatans/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  detail(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.get(`/api/kegiatans/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  delete(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.delete(`/api/kegiatans/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  switchMusic(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.post(`/api/kegiatans/${id}/switch-music`, 'type', new ApiOptions({ requestPayload }));
  },
  switchNomorUndian(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.post(`/api/kegiatans/${id}/switch-nomor-undian`, 'type', new ApiOptions({ requestPayload }));
  },
  changeFont(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.post(`/api/kegiatans/${id}/change-font-size`, 'type', new ApiOptions({ requestPayload }));
  },
  changeJenisUndian(requestPayload) {
    const { id } = requestPayload;
    delete requestPayload.id;
    return this.post(`/api/kegiatans/${id}/change-jenis-undian`, 'type', new ApiOptions({ requestPayload }));
  },
};
