/* eslint-disable no-unused-vars */
import ApiClient from '~/api';

const initialState = {
  isSuccess: [],
  list: [],
  data: {},
};

const getters = {
  getIsSuccess: state => state.isSuccess,
  getList: state => state.list,
  getData: state => state.data,
};

const actions = {
  async create({ commit }, params = null) {
    try {
      const res = await ApiClient.submitLangganan(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 
      
      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async edit({ edit }, params = null) {
    try {
      const res = await ApiClient.editLangganan(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 
      
      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async retrieveList({ commit }, params = null) {
    try {
      const res = await ApiClient.getListLangganan(params);
      if (typeof res.data !== 'undefined') {
        res.data.page = parseInt(res.data.page,10);
        commit('setList', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async retrieveDetail({ commit }, params = null) {
    try {
      const res = await ApiClient.getDetailLangganan(params);
      if (typeof res.data !== 'undefined') {
        commit('setData', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async delete({ _ }, params = null) {
    try {
      const res = await ApiClient.deleteLangganan(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 
      
      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
};

const mutations = {
  setIsSuccess(state, value) {
    state.isSuccess = value;
  },
  setList(state, value) {
    state.list = value;
  },
  setData(state, value) {
    state.data = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
