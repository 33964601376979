import Vue from 'vue';
import Badge from '~/components/template/Badge';
import BaseAlert from '~/components/template/BaseAlert';
import BaseButton from '~/components/template/BaseButton';
import BaseCheckbox from '~/components/template/BaseCheckbox';
import BaseInput from '~/components/template/BaseInput';
import BasePagination from '~/components/template/BasePagination';
import BaseProgress from '~/components/template/BaseProgress';
import BaseRadio from '~/components/template/BaseRadio';
import BaseSlider from '~/components/template/BaseSlider';
import BaseSwitch from '~/components/template/BaseSwitch';
import Card from '~/components/template/Card';
import Icon from '~/components/template/Icon';

Vue.component(Badge.name, Badge);
Vue.component(BaseAlert.name, BaseAlert);
Vue.component(BaseButton.name, BaseButton);
Vue.component(BaseInput.name, BaseInput);
Vue.component(BaseCheckbox.name, BaseCheckbox);
Vue.component(BasePagination.name, BasePagination);
Vue.component(BaseProgress.name, BaseProgress);
Vue.component(BaseRadio.name, BaseRadio);
Vue.component(BaseSlider.name, BaseSlider);
Vue.component(BaseSwitch.name, BaseSwitch);
Vue.component(Card.name, Card);
Vue.component(Icon.name, Icon);
