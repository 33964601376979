<template>
    <footer class="footer has-cards">
        <div class="container">
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        Copyright &copy; {{ getYear }} {{ getCompanyName }}
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
                        <li class="nav-item">
                            <nuxt-link to="/" class="nav-link" rel="noopener">Undian.in</nuxt-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'Footer',
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
    getCompanyName() {
      return process.env.COMPANY_NAME;
    },
  },
};
</script>
<style>
</style>
