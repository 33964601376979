class ApiOptions {
  constructor(additionalOptions = null) {
    const options = {
      responseErrorJson: true,
    };
    if (additionalOptions) {
      const { requestPayload, requestHeaders, requestLimit } = additionalOptions;
      if (requestHeaders) options.headers = requestHeaders;
      if (requestPayload) options.data = requestPayload;
      options.requestLimit = requestLimit;
    }
    this.options = options;
    return this.options;
  }
}

export default ApiOptions;
