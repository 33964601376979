import axios from 'axios';
import Cookies from 'js-cookie';
import { generateParams } from '~/api/fetch-helper';
import { generateGuid } from '~/helpers/guid';

const DEFAULT_REQUEST_ATTEMPT_LIMIT = 1;
const DEFAULT_REQUEST_TIME_LIMIT = 3600000; // in ms

class RequestManager {
  constructor(baseURL = '', requestLaneKey = 'requestLane') {
    this.baseURL = baseURL;
    this.tokenName = 'cxp_token';
    this.requestLaneKey = requestLaneKey;
    this.axiosClient = axios.create();
  }

  generateRequestParam(url, method, requestId, tokenObj, options) {
    const requestParam = {};

    if (Object.keys(options.data).length >= 1) {
      if (options.data.cancelToken) {
        requestParam.cancelToken = options.data.cancelToken;
        delete options.data.cancelToken;
      }

      requestParam.data = JSON.stringify({ ...options.data });
    }

    requestParam.headers = { ...options.headers, ...{ 'x-authorization': tokenObj } };
    requestParam.params = options.params;
    requestParam.url = url;
    requestParam.baseURL = this.baseURL;
    requestParam.method = method;
    requestParam.timeout = options.timeout;

    if (options.enableRequestId) {
      Object.assign(requestParam.headers, { 'X-Request-Id': requestId });
    }
    return requestParam;
  }

  async fetchToken() {
    return Cookies.get(this.tokenName);
  }

  async requestWithToken(callback) {
    const tokenResponse = await this.fetchToken();
    return callback(tokenResponse);
  }

  request(url, method, scope = 'public', inputOptions = {}) {
    this.requestKey = `${this.requestLaneKey}-${scope}-${method.toLowerCase()}-${url.replace(/\/\w+\d+\w+/, '')}`;
    if (inputOptions.requestLimit && inputOptions.requestLimit.additionalKey) this.requestKey += `-${inputOptions.requestLimit.additionalKey}`;
    if (inputOptions.requestLimit && this.needToLimitRequest(inputOptions.requestLimit)) return Promise.reject(new Error('REQUEST LIMITED'));

    const requestId = generateGuid();
    const options = { ...RequestManager.defaultRequestOptions(), ...inputOptions };

    return this.requestWithToken(tokenObj => {
      return new Promise((resolve, reject) => {
        const requestParam = this.generateRequestParam(url, method, requestId, tokenObj, options);
        this.axiosClient
          .request(requestParam)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            // If unauthorized
            if (axios.isCancel(err)) {
              console.info('Request canceled', err.message);
            } else if (err && err.response.status === 401) {
              if (url !== '/api/v1/logout') {
                window.location = '/logout';
              } else {
                resolve();
              }
            } else {
              reject(err);
            }
          });
      });
    });
  }

  needToLimitRequest(requestLimit) {
    if (!requestLimit.attempt && !requestLimit.time) return false;
    const attemptLimit = requestLimit.attempt || DEFAULT_REQUEST_ATTEMPT_LIMIT;
    const timeLimit = requestLimit.time || DEFAULT_REQUEST_TIME_LIMIT;
    const { limitedRequestPool } = RequestManager;
    const limitedRequest = limitedRequestPool.get(this.requestKey);
    if (!limitedRequest) {
      this.registerLimitedRequest(requestLimit);
      return false;
    }
    const { attempt, requestedAt } = limitedRequest;
    const isLimitedByAttempt = attempt > attemptLimit - 1;
    const isLimitedByTime = new Date(requestedAt).getTime() + timeLimit > new Date().getTime();
    if (!isLimitedByTime) {
      this.registerLimitedRequest(requestLimit);
      return false;
    }
    if (isLimitedByAttempt) return true;
    limitedRequestPool.set(this.requestKey, { attempt: attempt + 1, requestedAt });
    this.removeLimitedRequest(requestLimit);
    return false;
  }

  registerLimitedRequest(requestLimit) {
    const timeLimit = requestLimit.time || DEFAULT_REQUEST_TIME_LIMIT;
    const { limitedRequestPool } = RequestManager;
    limitedRequestPool.set(this.requestKey, { attempt: 1, requestedAt: Date.now() });
    setTimeout(() => {
      this.removeLimitedRequest(requestLimit);
    }, timeLimit + 1);
  }

  removeLimitedRequest(requestLimit) {
    if (!requestLimit) return;
    const { limitedRequestPool } = RequestManager;
    const limitedRequest = limitedRequestPool.get(this.requestKey);
    if (!limitedRequest) return;
    const { attempt, requestedAt } = limitedRequest;
    const timeLimit = requestLimit.time || DEFAULT_REQUEST_TIME_LIMIT;
    const isExpired = new Date(requestedAt).getTime() + timeLimit < new Date().getTime();
    if (!isExpired) return;
    if (attempt <= 1) {
      limitedRequestPool.delete(this.requestKey);
    } else {
      limitedRequestPool.set(this.requestKey, { attempt: attempt - 1, requestedAt });
    }
  }
}

// RequestManager.requestPool = new Map();
RequestManager.limitedRequestPool = new Map();

RequestManager.defaultRequestOptions = function defaultRequestOptions() {
  return {
    data: {},
    headers: { 'Content-Type': 'application/json' },
    params: {},
    enableRequestId: false,
    responseErrorJson: false,
    timeout: 0,
  };
};

['GET', 'POST', 'PATCH', 'PUT', 'DELETE'].forEach(method => {
  const methodName = method.toLowerCase();

  RequestManager.prototype[methodName] = function requestWrapper(url, scope = 'public', options = {}) {
    let fullUrl = url;
    if (methodName === 'get') {
      fullUrl += generateParams(options.data);
    }
    return this.request(fullUrl, method, scope, options);
  };
});

export default RequestManager;
