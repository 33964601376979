import Auth from './modules/auth-api';
import Type from './modules/type-api';
import Option from './modules/option-api';
import Kegiatan from './modules/kegiatan-api';
import Client from './modules/client-api';
import Tahap from './modules/tahap-api';
import Undian from './modules/undian-api';
import User from './modules/user-api';
import Langganan from './modules/langganan-api';

export default {
  Auth,
  Type,
  Option,
  Kegiatan,
  Client,
  Tahap,
  Undian,
  User,
  Langganan,
};
