<template>
  <header class="header-global">
    <base-nav class="navbar-main" effect="light" expand transparent type="">
      <nuxt-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img alt="logo" src="~/static/img/wof.png"> Undian.in
      </nuxt-link>

      <div slot="content-header" slot-scope="{closeMenu}" class="row">
        <div class="col-6 collapse-brand">
          <nuxt-link slot="brand" class="navbar-brand mr-lg-5 mt-0" to="/">
            <img alt="logo" src="~/static/img/wof.png"> Undian.in
          </nuxt-link>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <li class="nav-item">
          <nuxt-link slot="title" class="nav-link" data-toggle="dropdown" to="/" role="button">
            Home
          </nuxt-link>
        </li>
        <li class="nav-item">
          <nuxt-link slot="title" class="nav-link" data-toggle="dropdown" to="/kegiatan" role="button">
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Kegiatan</span>
          </nuxt-link>
        </li>
        <base-dropdown tag="li" class="nav-item" v-if="user.role === '1'">
          <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
              <i class="ni ni-collection d-lg-none"></i>
              <span class="nav-link-inner--text" style="display: flex;">Kelola Data <i class="ml-2 fa fa-sort-down"></i></span>
          </a>
          <router-link to="/client" class="dropdown-item">Client</router-link>
          <router-link to="/user" class="dropdown-item">User</router-link>
          <router-link to="/langganan" class="dropdown-item">Langganan</router-link>
        </base-dropdown>
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <base-dropdown tag="li" class="nav-item d-none d-lg-block text-white">
          <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
              <i class="fa fa-user mr-2"></i> {{user.name}} | {{user.email}}
          </a>
          <router-link to="/ganti-password" class="dropdown-item">Ganti Password</router-link>
        </base-dropdown>
        <li class="nav-item">
          <nuxt-link class="nav-link nav-link-icon" data-toggle="tooltip" to="/logout" title="Logout">
            <i class="fa fa-sign-out"></i>
            <span class="nav-link-inner--text d-lg-none">Logout</span>
          </nuxt-link>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseNav from '~/components/template/BaseNav';
import BaseDropdown from '~/components/template/BaseDropdown';
import CloseButton from '~/components/template/CloseButton';

export default {
  name: 'Header',
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
};
</script>
<style>
</style>
