import ApiOptions from '../api-options';

export default {
  list(requestPayload) {
    const { idKegiatan } = requestPayload;
    delete requestPayload.idKegiatan;
    return this.get(`/api/kegiatans/${idKegiatan}/undians`, 'type', new ApiOptions({ requestPayload }));
  },
  submit(requestPayload) {
    const { idKegiatan } = requestPayload;
    delete requestPayload.idKegiatan;
    return this.post(`/api/kegiatans/${idKegiatan}/undians`, 'type', new ApiOptions({ requestPayload }));
  },
  reset(requestPayload) {
    const { idKegiatan } = requestPayload;
    delete requestPayload.idKegiatan;
    return this.post(`/api/kegiatans/${idKegiatan}/reset`, 'type', new ApiOptions({ requestPayload }));
  },
  permanent(requestPayload) {
    const { idKegiatan } = requestPayload;
    delete requestPayload.idKegiatan;
    return this.post(`/api/kegiatans/${idKegiatan}/permanent`, 'type', new ApiOptions({ requestPayload }));
  },
  play(requestPayload) {
    const { idKegiatan } = requestPayload;
    delete requestPayload.idKegiatan;
    return this.post(`/api/kegiatans/${idKegiatan}/play`, 'type', new ApiOptions({ requestPayload }));
  },
  prePlay(requestPayload) {
    const { idKegiatan } = requestPayload;
    delete requestPayload.idKegiatan;
    return this.get(`/api/kegiatans/${idKegiatan}/pre-play`, 'type', new ApiOptions({ requestPayload }));
  },
  upload(requestPayload) {
    const { idKegiatan } = requestPayload;
    delete requestPayload.idKegiatan;
    return this.post(`/api/kegiatans/${idKegiatan}/upload-undian`, 'type', new ApiOptions({ requestPayload }));
  },
  edit(requestPayload) {
    const { idKegiatan, id } = requestPayload;
    delete requestPayload.id;
    delete requestPayload.idKegiatan;
    return this.put(`/api/kegiatans/${idKegiatan}/undians/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  detail(requestPayload) {
    const { idKegiatan, id } = requestPayload;
    delete requestPayload.id;
    delete requestPayload.idKegiatan;
    return this.get(`/api/kegiatans/${idKegiatan}/undians/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  delete(requestPayload) {
    const { idKegiatan, id } = requestPayload;
    delete requestPayload.id;
    delete requestPayload.idKegiatan;
    return this.delete(`/api/kegiatans/${idKegiatan}/undians/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
};
