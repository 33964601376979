import RequestManager from './request-manager';

function InterfaceWrapper(interfaces = {}, requestLaneKey = 'requestLane') {
  const requestManager = new RequestManager(process.env.API_HOST, requestLaneKey);
  const wrappedInterfaces = {};

  Object.keys(interfaces).forEach(key => {
    wrappedInterfaces[key] = (...args) => {
      return interfaces[key].bind(requestManager)(...args);
    };
  });

  return wrappedInterfaces;
}

export default InterfaceWrapper;
