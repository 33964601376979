import ApiOptions from '../api-options';

export default {
  login(requestPayload) {
    return this.post('/api/login', 'auth', new ApiOptions({ requestPayload }));
  },
  logout() {
    return this.post('/api/logout', 'auth');
  },
};
