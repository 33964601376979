<template>
  <div v-show="Object.keys(toasts).length >= 1">
    <div class="c-toast">
      <transition-group name="fade">
        <ToastItem v-for="(toast, i) in toasts" :key="toast.id" :index="i" :toast="toast" @close="close" />
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToastItem from '~/components/toastItem';

export default {
  name: 'Toast',
  components: { ToastItem },
  // watch: {
  //   $route () {
  //     this.$store.dispatch('toast/removeAllMessage');
  //   },
  // },
  computed: {
    ...mapGetters({
      toasts: 'toast/toasts',
    }),
  },
  methods: {
    close(id) {
      this.$store.dispatch('toast/removeMessage', { id });
    },
  },
};
</script>
