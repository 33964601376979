import ApiOptions from '../api-options';

export default {
  list(requestPayload) {
    const { idKegiatan } = requestPayload;
    delete requestPayload.idKegiatan;
    return this.get(`/api/kegiatans/${idKegiatan}/tahaps`, 'type', new ApiOptions({ requestPayload }));
  },
  submit(requestPayload) {
    const { idKegiatan } = requestPayload;
    delete requestPayload.idKegiatan;
    return this.post(`/api/kegiatans/${idKegiatan}/tahaps`, 'type', new ApiOptions({ requestPayload }));
  },
  edit(requestPayload) {
    const { idKegiatan, id } = requestPayload;
    delete requestPayload.id;
    delete requestPayload.idKegiatan;
    return this.put(`/api/kegiatans/${idKegiatan}/tahaps/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  detail(requestPayload) {
    const { idKegiatan, id } = requestPayload;
    delete requestPayload.id;
    delete requestPayload.idKegiatan;
    return this.get(`/api/kegiatans/${idKegiatan}/tahaps/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
  delete(requestPayload) {
    const { idKegiatan, id } = requestPayload;
    delete requestPayload.id;
    delete requestPayload.idKegiatan;
    return this.delete(`/api/kegiatans/${idKegiatan}/tahaps/${id}`, 'type', new ApiOptions({ requestPayload }));
  },
};
