/* eslint-disable no-unused-vars */
import ApiClient from '~/api';

const initialState = {
  isSuccess: [],
  list: [],
  data: {},
  prePlay: {},
};

const getters = {
  getIsSuccess: state => state.isSuccess,
  getList: state => state.list,
  getData: state => state.data,
  getPrePlay: state => state.prePlay,
};

const actions = {
  async create({ commit }, params = null) {
    try {
      const res = await ApiClient.submitUndian(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 
      
      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async upload({ commit }, params = null) {
    try {
      const res = await ApiClient.uploadUndian(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 
      
      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async edit({ edit }, params = null) {
    try {
      const res = await ApiClient.editUndian(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 
      
      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async retrieveList({ commit }, params = null) {
    try {
      const res = await ApiClient.getListUndian(params);
      if (typeof res.data !== 'undefined') {
        res.data.page = parseInt(res.data.page,10);
        commit('setList', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async retrieveDetail({ commit }, params = null) {
    try {
      const res = await ApiClient.getDetailUndian(params);
      if (typeof res.data !== 'undefined') {
        commit('setData', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async delete({ commit }, params = null) {
    try {
      const res = await ApiClient.deleteUndian(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 

      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async prePlay({ commit }, params = null) {
    try {
      const res = await ApiClient.prePlayUndian(params);
      if (typeof res.data !== 'undefined') {
        commit('setPrePlay', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async play({ commit }, params = null) {
    try {
      const res = await ApiClient.playUndian(params);
      if (typeof res.data !== 'undefined') {
        commit('setData', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async reset(_, params = null) {
    try {
      const res = await ApiClient.resetUndian(params);
      if (typeof res.data === 'undefined') {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async permanent(_, params = null) {
    try {
      const res = await ApiClient.permanentUndian(params);
      if (typeof res.data === 'undefined') {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
};

const mutations = {
  setIsSuccess(state, value) {
    state.isSuccess = value;
  },
  setList(state, value) {
    state.list = value;
  },
  setData(state, value) {
    state.data = value;
  },
  setPrePlay(state, value) {
    state.prePlay = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
