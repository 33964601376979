import InterfaceWrapper from '~/api/interface-wrapper';
import Interface from '~/api/interface';

const ApiClient = {
  ep: {},
  async init() {
    const maps = [
      { entry: 'auth', interface: Interface.Auth, pool: 'auth' },
      { entry: 'type', interface: Interface.Type, pool: 'type' },
      { entry: 'option', interface: Interface.Option, pool: 'option' },
      { entry: 'kegiatan', interface: Interface.Kegiatan, pool: 'kegiatan' },
      { entry: 'client', interface: Interface.Client, pool: 'client' },
      { entry: 'tahap', interface: Interface.Tahap, pool: 'tahap' },
      { entry: 'undian', interface: Interface.Undian, pool: 'undian' },
      { entry: 'user', interface: Interface.User, pool: 'user' },
      { entry: 'langganan', interface: Interface.Langganan, pool: 'langganan' },
    ];
    maps.map(item => {
      this.ep[item.entry] = InterfaceWrapper(item.interface, `xhr_ep_${item.pool}`);
      return item;
    });
  },

  // Auth
  login(params = {}) {
    return this.ep.auth.login(params);
  },
  logout() {
    return this.ep.auth.logout();
  },

  // type
  getListType(params = {}) {
    return this.ep.type.list(params);
  },
  getDetailType(params = {}) {
    return this.ep.type.detail(params);
  },

  // option
  getListOption(params = {}) {
    return this.ep.option.list(params);
  },
  getDetailOption(params = {}) {
    return this.ep.option.detail(params);
  },

  // kegiatan
  getListKegiatan(params = {}) {
    return this.ep.kegiatan.list(params);
  },
  getDetailKegiatan(params = {}) {
    return this.ep.kegiatan.detail(params);
  },
  submitKegiatan(params = {}) {
    return this.ep.kegiatan.submit(params);
  },
  editKegiatan(params = {}) {
    return this.ep.kegiatan.edit(params);
  },
  deleteKegiatan(params = {}) {
    return this.ep.kegiatan.delete(params);
  },
  switchMusicKegiatan(params = {}) {
    return this.ep.kegiatan.switchMusic(params);
  },
  switchNomorUndianKegiatan(params = {}) {
    return this.ep.kegiatan.switchNomorUndian(params);
  },
  changeFont(params = {}) {
    return this.ep.kegiatan.changeFont(params);
  },
  changeJenisUndian(params = {}) {
    return this.ep.kegiatan.changeJenisUndian(params);
  },

  // tahap
  getListTahap(params = {}) {
    return this.ep.tahap.list(params);
  },
  getDetailTahap(params = {}) {
    return this.ep.tahap.detail(params);
  },
  submitTahap(params = {}) {
    return this.ep.tahap.submit(params);
  },
  editTahap(params = {}) {
    return this.ep.tahap.edit(params);
  },
  deleteTahap(params = {}) {
    return this.ep.tahap.delete(params);
  },

  // undian
  getListUndian(params = {}) {
    return this.ep.undian.list(params);
  },
  getDetailUndian(params = {}) {
    return this.ep.undian.detail(params);
  },
  submitUndian(params = {}) {
    return this.ep.undian.submit(params);
  },
  uploadUndian(params = {}) {
    return this.ep.undian.upload(params);
  },
  editUndian(params = {}) {
    return this.ep.undian.edit(params);
  },
  deleteUndian(params = {}) {
    return this.ep.undian.delete(params);
  },
  prePlayUndian(params = {}) {
    return this.ep.undian.prePlay(params);
  },
  playUndian(params = {}) {
    return this.ep.undian.play(params);
  },
  resetUndian(params = {}) {
    return this.ep.undian.reset(params);
  },
  permanentUndian(params = {}) {
    return this.ep.undian.permanent(params);
  },

  // client
  getListClient(params = {}) {
    return this.ep.client.list(params);
  },
  getDetailClient(params = {}) {
    return this.ep.client.detail(params);
  },
  submitClient(params = {}) {
    return this.ep.client.submit(params);
  },
  editClient(params = {}) {
    return this.ep.client.edit(params);
  },
  deleteClient(params = {}) {
    return this.ep.client.delete(params);
  },

  // user
  gantiPassword(params = {}) {
    return this.ep.user.gantiPassword(params);
  },
  retrieveKuotaKegiatan(params = {}) {
    return this.ep.user.kuotaKegiatan(params);
  },
  retrieveHome(params = {}) {
    return this.ep.user.home(params);
  },
  getListUser(params = {}) {
    return this.ep.user.list(params);
  },
  getDetailUser(params = {}) {
    return this.ep.user.detail(params);
  },
  submitUser(params = {}) {
    return this.ep.user.submit(params);
  },
  editUser(params = {}) {
    return this.ep.user.edit(params);
  },
  deleteUser(params = {}) {
    return this.ep.user.delete(params);
  },

  // langganan
  getListLangganan(params = {}) {
    return this.ep.langganan.list(params);
  },
  getDetailLangganan(params = {}) {
    return this.ep.langganan.detail(params);
  },
  submitLangganan(params = {}) {
    return this.ep.langganan.submit(params);
  },
  editLangganan(params = {}) {
    return this.ep.langganan.edit(params);
  },
  deleteLangganan(params = {}) {
    return this.ep.langganan.delete(params);
  },
};
ApiClient.init();

export default ApiClient;
