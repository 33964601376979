/* eslint-disable no-unused-vars */
import ApiClient from '~/api';

const initialState = {
  user: {},
  isSuccess: [],
  list: [],
  data: {},
  home: {},
  kuotaKegiatan: {},
};

const getters = {
  getUser: state => state.user,
  getIsSuccess: state => state.isSuccess,
  getList: state => state.list,
  getData: state => state.data,
  getHome: state => state.home,
  getKuotaKegiatan: state => state.kuotaKegiatan,
};

const actions = {
  retrieveFromCookies({ commit }) {
    commit('setUser', this.$cookies.get('user'));
  },
  setUser({ commit }, params = null) {
    const { user } = params;
    commit('setUser', user);
  },

  async retrieveKuotaKegiatan({ commit }, params = null) {
    try {
      const res = await ApiClient.retrieveKuotaKegiatan();
      if (typeof res.data !== 'undefined') {
        commit('setKuotaKegiatan', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async retrieveHome({ commit }, params = null) {
    try {
      const res = await ApiClient.retrieveHome();
      if (typeof res.data !== 'undefined') {
        commit('setHome', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  // ganti password
  async gantiPassword({ commit }, params = null) {
    try {
      const res = await ApiClient.gantiPassword(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 
      
      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  // manage user;
  async create({ commit }, params = null) {
    try {
      const res = await ApiClient.submitUser(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 
      
      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async edit({ edit }, params = null) {
    try {
      const res = await ApiClient.editUser(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 
      
      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async retrieveList({ commit }, params = null) {
    try {
      const res = await ApiClient.getListUser(params);
      if (typeof res.data !== 'undefined') {
        res.data.page = parseInt(res.data.page,10);
        commit('setList', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async retrieveDetail({ commit }, params = null) {
    try {
      const res = await ApiClient.getDetailUser(params);
      if (typeof res.data !== 'undefined') {
        commit('setData', res.data);
      } else {
        throw new Error(['Ooops! Internal Server Error']).message;
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async delete({ _ }, params = null) {
    try {
      const res = await ApiClient.deleteUser(params);
      if (typeof res.data !== 'undefined') {
        return Promise.resolve();
      } 
      
      throw new Error(['Ooops! Internal Server Error']).message;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
};

const mutations = {
  setUser(state, value) {
    state.user = value;
  },
  setHome(state, value) {
    state.home = value;
  },

  setIsSuccess(state, value) {
    state.isSuccess = value;
  },
  setList(state, value) {
    state.list = value;
  },
  setData(state, value) {
    state.data = value;
  },
  setKuotaKegiatan(state, value) {
    state.kuotaKegiatan = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
